/* eslint-disable @typescript-eslint/no-var-requires */
const path = require("path");

//locales for both environments
const defaultLocales = [
  "en",
  "ar",
  "az",
  "bn",
  "de",
  "es",
  "fa",
  "fr",
  "ky",
  "my",
  "pl",
  "ptBR",
  "it",
  "id",
  "ru",
  "tr",
  "vi",
  "zhCN",
  "zhTW"
]

// Locales for .dev
const devLocales = [
  "he",
  "ko",
  "ja",
]

// Evaluates if environment .dev, concat and sorts locales
const locales = 
  process.env.NEXT_PUBLIC_DOMAIN != "climatescience.org" 
    ? defaultLocales
      .concat(devLocales)
      .sort()
    : defaultLocales

module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: locales
  },
  nsSeparator: ":",
  ns: [
    "_links",
    "blog",
    "common",
    "offline",
    "nav",
    "footer",
    "seo",
    "courses",
    "donate",
    "auth",
    "join",
    "landing-page",
    "schools",
    "contact",
    "explore",
    "partnerships",
    "lessons",
    "clubs",
    "certificate",
    "business",
    "settings",
    "about",
    "olympiad",
    "our-story",
    "community",
    "business",
    "children",
    "media-kit",
    "teachers",
    "custom404",
    "custom500",
  ],
  localePath: path.resolve("./src/main/public/locales"),
};
